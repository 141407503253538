import { render } from "./OrderAudit.vue?vue&type=template&id=386686be&scoped=true"
import script from "./OrderAudit.vue?vue&type=script&lang=js"
export * from "./OrderAudit.vue?vue&type=script&lang=js"

import "./OrderAudit.vue?vue&type=style&index=0&id=386686be&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-386686be"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QImg,QBtn,QBanner,QDialog,QAvatar,QCardActions});qInstall(script, 'directives', {ClosePopup});
