<template>
  <div class="mytitle text-center">Paid Orders</div>
  <div v-if="myprofile.role == 1">
    <div
      v-if="mytxns.length > 0"
      class="
        q-pa-md q-gutter-md
        fit
        row
        wrap
        justify-center
        items-start
        content-start
      "
    >
      <q-card v-for="k in mytxns" :key="k.id" class="my-card">
        <q-card-section v-if="k.nfts" horizontal>
          <q-img
            class="my-nft-img q-ma-md"
            ratio="1"
            :src="k.nfts.data.image"
            @click="this.$router.push('/nft/' + k.contract)"
          />

          <q-card-section>
            <p class="text-h6">
              {{ k.nfts.data.name }}
              <span v-if="k.status == 'DONE'" class="text-blue">
                #{{ k.tokenid }} of {{ k.nfts.data.count }}
              </span>
            </p>

            <p class="text-body2 text-blue">
              {{ $t("purchase_date") }}: {{ pdate(k.created_at) }}
            </p>
            <p class="text-body2 text-orange">
              {{ $t("purchase_price") }}: ${{ k.price }}
            </p>
            <p class="text-body">
              {{ $t("order_id") }}: {{ k.id }} {{ $t("status") }}:
              {{ k.status }}
            </p>

            <p class="text-body">
              Seller: <a :href="'/profile/' + k.ufrom.id"> {{ k.ufrom.id }}</a>
              {{ k.ufrom.nickname }}
            </p>
            <p class="text-body">
              Buyer: <a :href="'/profile/' + k.uto.id"> {{ k.uto.id }}</a>
              {{ k.uto.nickname }}
            </p>

            <p class="text-body">
              Contract:
              <a :href="chainscan_url + 'address/' + k.contract">
                {{
                  k.contract.substring(0, 15) + "..." + k.contract.substring(35)
                }}
              </a>
            </p>
            <div class="text-body">
              TID:
              {{
                k.tid.length > 15
                  ? k.tid.substring(0, 15) + "..." + k.tid.substring(70)
                  : k.tid
              }}
            </div>
            <p>Stripe ID: {{ k.payment_id }}</p>
            <p v-if="k.txn" class="text-body>">
              <a :href="chainscan_url + 'tx/' + k.txn.data.transactionHash">
                Txn:
                {{
                  k.txn.data.transactionHash.substring(0, 15) +
                  "..." +
                  k.txn.data.transactionHash.substring(50)
                }}
              </a>
            </p>
            <q-btn
              v-if="!k.txn && k.status == 'PAID' && k.type != 'MEMBER'"
              class="q-mr-md"
              color="blue"
              @click="
                mint_confirm = true;
                cur_txn = k;
              "
            >
              {{ $t("mint") }}
            </q-btn>
            <q-btn
              v-if="k.type != 'MEMBER' && k.txn != null"
              class="q-mr-md"
              color="blue"
              @click="patch(k)"
            >
              Create NFT
            </q-btn>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <div
      v-else
      class="
        q-pa-md q-gutter-md
        fit
        row
        wrap
        justify-center
        items-start
        content-start
      "
    >
      <q-banner rounded class="bg-orange text-white">
        You don't have any orders.
      </q-banner>
    </div>
  </div>
  <div v-else>
    <h4 class="text-center">Access denied</h4>
  </div>

  <q-dialog v-model="mint_confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm"
          >You are minting 1 token of NFT to your customer wallet!</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          flat
          label="MINT"
          color="orange"
          v-close-popup
          @click="mint_to_customer()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 800px

.my-nft-img
  min-width: 250px
  width: 250px
  height: 250px
  cursor: pointer

p
  padding: 0px
  margin: 2px
</style>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { supabase as s } from "../supabase";
import { useQuasar } from "quasar";
import axios from "axios";
import { APIURL, chainscan, next_tid, next_token_id } from "../web3.js";
export default {
  name: "OrderAudit",
  setup() {
    const user = s.auth.user();
    console.log(user);
    const mytxns = ref([]);
    const chainscan_url = ref(chainscan);
    const q = useQuasar();
    onBeforeMount(async () => {
      let { data: txns, error } = await s
        .from("txns")
        .select("*,nfts(data),ufrom:ufrom(*),uto:uto(*)")
        .eq("status", "PAID")
        .order("created_at", { ascending: false });
      if (error) console.log(error);
      console.log(txns);
      mytxns.value = txns;
    });
    return {
      user,
      mytxns,
      chainscan_url,
      mint_confirm: ref(false),
      q,
      cur_txn: ref(null),
      myprofile:inject("profile")
    };
  },
  methods: {
    async patch(txn) {
      // PATCH: if no token, create it
      const checknft = await s
        .from("tokens")
        .select("*")
        .eq("tid", txn.tid)
        .limit(1);
      if (checknft.error) {
        console.log("checknft error:", checknft.error);
      } else if (checknft.data.length < 1) {
        alert("Not exist! Need create it");
        // create it
        // database insert token record
        let tokenid = txn.tokenid == null ? 0 : txn.tokenid;
        const newnft = await s.from("tokens").insert([
          {
            owner: txn.to,
            balance: 1,
            data: txn.nfts.data,
            tokenid: tokenid,
            tid: txn.tid,
            contract: txn.contract,
            uowner: txn.uto.id,
          },
        ]);
        if (newnft.error) {
          console.log("newnft error:", newnft.error);
        } else {
          console.log("PATCH: Create token if not exist,", newnft.data);
          this.q.notify("PATCH: Create NFT success!");
        }
      } else {
        this.q.notify("NFT exist, ignore!" + JSON.stringify(checknft.data));
      }
      // PATCH
    },
    show_nft(id) {
      this.$router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    async mint_to_customer() {
      // call mint
      // reduce balance after sucess
      // change current order status
      // alert(JSON.stringify(txn));
      let txn = { ...this.cur_txn };
      console.log("Call mint_to_customer", txn);

      this.q.loading.show();
      const url = APIURL + "/i/mint_token";

      const mintto = txn.to;
      const tid = txn.tid;
      const tokenid = txn.tokenid;

      const params = {
        to: mintto,
        contract: txn.contract,
        amount: 1,
        tid: tid,
        tokenid: tokenid,
        uuid: this.user.id,
        err:'no'
      };
      console.log("mint token params：", params);
      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log("mint req:", options);
      let that = this;
      axios(options)
        .then(async (res) => {
          console.log("mint api response:", res);
          if (res.data.blockHash != undefined) {
            console.log(res.data);
            {
              that.q.loading.hide();
              that.q.notify(
                "Mint 1 Token of " +
                  txn.nfts.data.name +
                  " to " +
                  txn.to +
                  " SUCCESS!"
              );

              // Update TXN record
              this.cur_txn.txn = res;
              const record = {
                txn: res,
              };
              const { data, error } = await s
                .from("txns")
                .update([record])
                .match({ id: txn.id });

              if (error) {
                console.log(error);
              } else {
                console.log(data);
                that.q.notify("Update your order set txn success.");
              }
            }
          } else {
            console.log("ERR:blockchain transaction");
          }
        })
        .catch(function (error) {
          that.q.loading.hide();
          console.log(error);
          that.q.notify(error.message);
          that.q.notify(error.response.data);
          // that.q.notify(JSON.stringify(error))

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    },
  },
};
</script>